<template>
    <div class="activities-box">
        <div class="card">
            <vue-tabs v-model="activeTab">
                <v-tab title="Text" icon="fas fa-sms" class="p-0">
                    <feed-form
                        :endpoint="messageEndpoint"
                        :parent-data="formData"
                        @saved="$emit('message-saved', $event)"
                    />
                </v-tab>
                <v-tab title="Call" icon="fas fa-phone" :disabled="true">
                    Call content
                </v-tab>


                <v-tab title="Email" icon="fas fa-envelope" :disabled="true">
                    Email content
                </v-tab>

                <v-tab
                    v-if="isChatActive"
                    title="Chat"
                    icon="fas fa-headset"
                    style="height: 500px;"
                >
                    <dealer-chat
                        v-if="formData.id"
                        ref="chat"
                        token-field="channel_client_token"
                        endpoint="/chats"
                        :show-header="false"
                        :http-options="{ baseURL, headers: { Authorization: token }}"
                        :display-full="false"
                        :contacts="null"
                        :receiver="formData.id"
                        @action-called="openDealerList"
                    />
                </v-tab>

                <v-tab title="Notes" icon="fas fa-clipboard" :disabled="true">
                    Notes content
                </v-tab>

                <v-tab title="Add activity" icon="fas fa-calendar-day" :disabled="true">
                    Add activity content
                </v-tab>

                <v-tab title="Upload files" icon="fas fa-cloud-upload-alt" :disabled="true">
                    Upload files content
                </v-tab>
            </vue-tabs>
        </div>

        <modal
            :draggable="false"
            :adaptive="true"
            :scrollable="true"
            :width="1000"
            name="dealer-modal"
            height="auto"
        >
            <dealer-vehicle-list @selected="processSelected" @cancel="closeDealerList" />
        </modal>
    </div>
</template>

<script>
const { TwilioChat: DealerChat } = require(`@/import.${process.env.VUE_APP_IMPORTS}`);
import { VueTabs, VTab } from "vue-nav-tabs";
import FeedForm from "@c/feed/feed-form";
import "vue-nav-tabs/themes/vue-tabs.css";
import DealerVehicleList from "@c/dealer/vehicle-list";
import { mapGetters } from "vuex";

export default {
    name: "ActivitiesBox",
    components: {
        VueTabs,
        VTab,
        FeedForm,
        DealerChat,
        DealerVehicleList
    },
    props:{
        formData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            activeTab: "",
            baseURL: process.env.VUE_APP_BASE_API_URL,
            queryParams: {
                sort: "sort",
                page: "page",
                perPage: "limit"
            },
            token: this.$store.state.User.token || Cookies.get("token")
        }
    },
    computed: {
        ...mapGetters({
            isChatActive: "Application/isChatActive"
        }),
        messageEndpoint() {
            return `/messages`;
        }
    },
    watch: {
        "$route.path": {
            handler() {
                if (this.$route.query.tab == "chat") {
                    this.activeTab = "Chat";
                }
            },
            immediate: true
        },
        activeTab() {
            this.$router.replace({
                name: "edit-resource",
                params: {
                    id: this.$route.params.id,
                    resource: "leads"
                },
                query: {
                    tab: this.activeTab.toLowerCase()
                }
            })
        }
    },
    methods: {
        openDealerList() {
            this.$modal.show("dealer-modal");
        },
        closeDealerList() {
            this.$modal.hide("dealer-modal");
        },

        processSelected(items) {
            this.$modal.hide("dealer-modal");
            items.forEach((item) => {
                this.$refs.chat.sendMessage(item.message, item.attributes)
            })

        },

        axiosUpload(url) {
            return axios({
                method: "post",
                url
            })
        }
    }
}
</script>

<style lang="scss">
.activities-box {
    margin-bottom: 40px;

    .card {
        padding: 0;
    }
}

.tab.disabled a {
    color: gray !important;
}
</style>
